// Generated by Framer (2c4b293)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["R8EfoIdwC", "tzxlH6W1a", "kczMhKA1U", "TNAyw1k3l"];

const serializationHash = "framer-jCdGa"

const variantClassNames = {kczMhKA1U: "framer-v-mwdmyx", R8EfoIdwC: "framer-v-knuhmx", TNAyw1k3l: "framer-v-1bfmo6e", tzxlH6W1a: "framer-v-eyi9a9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "R8EfoIdwC", "Variant 2": "tzxlH6W1a", "Variant 3": "kczMhKA1U", "Variant 4": "TNAyw1k3l"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, SaCVjgRzY: image ?? props.SaCVjgRzY ?? {src: "https://framerusercontent.com/images/qtNwDQ7X6g9bO2h9H0cU9NFFU3I.svg"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "R8EfoIdwC"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, SaCVjgRzY, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "R8EfoIdwC", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterbf4570 = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
setVariant("tzxlH6W1a")
})

const onMouseLeaveqpyp3f = activeVariantCallback(async (...args) => {
setGestureState({isHovered: false})
setVariant("R8EfoIdwC")
})

const onTap1vg9x96 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("TNAyw1k3l")
})

const onTap15gddl = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("kczMhKA1U")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://bramiplus.surge.sh"} openInNewTab><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 375, intrinsicWidth: 375, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 375, pixelWidth: 375, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(SaCVjgRzY)}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-knuhmx", className, classNames)} framer-1miav3z`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"R8EfoIdwC"} onMouseEnter={onMouseEnterbf4570} ref={ref ?? ref1} style={{filter: "none", WebkitFilter: "none", ...style}} variants={{TNAyw1k3l: {filter: "blur(5px)", WebkitFilter: "blur(5px)"}, tzxlH6W1a: {filter: "blur(5px)", WebkitFilter: "blur(5px)"}}} {...addPropertyOverrides({kczMhKA1U: {"data-framer-name": "Variant 3", onMouseEnter: undefined, onTap: onTap1vg9x96}, TNAyw1k3l: {"data-framer-name": "Variant 4", onMouseEnter: undefined, onTap: onTap15gddl}, tzxlH6W1a: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeaveqpyp3f}}, baseVariant, gestureVariant)}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jCdGa.framer-1miav3z, .framer-jCdGa .framer-1miav3z { display: block; }", ".framer-jCdGa.framer-knuhmx { height: 138px; position: relative; text-decoration: none; width: 427px; }", ".framer-jCdGa.framer-v-eyi9a9.framer-knuhmx { aspect-ratio: 3.0942028985507246 / 1; height: var(--framer-aspect-ratio-supported, 138px); }", ".framer-jCdGa.framer-v-mwdmyx.framer-knuhmx, .framer-jCdGa.framer-v-1bfmo6e.framer-knuhmx { aspect-ratio: 3.0942028985507246 / 1; cursor: pointer; height: var(--framer-aspect-ratio-supported, 138px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 138
 * @framerIntrinsicWidth 427
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"tzxlH6W1a":{"layout":["fixed","fixed"]},"kczMhKA1U":{"layout":["fixed","fixed"]},"TNAyw1k3l":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"SaCVjgRzY":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLs5rLiOI2: React.ComponentType<Props> = withCSS(Component, css, "framer-jCdGa") as typeof Component;
export default FramerLs5rLiOI2;

FramerLs5rLiOI2.displayName = "brami c";

FramerLs5rLiOI2.defaultProps = {height: 138, width: 427};

addPropertyControls(FramerLs5rLiOI2, {variant: {options: ["R8EfoIdwC", "tzxlH6W1a", "kczMhKA1U", "TNAyw1k3l"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4"], title: "Variant", type: ControlType.Enum}, SaCVjgRzY: {__defaultAssetReference: "data:framer/asset-reference,qtNwDQ7X6g9bO2h9H0cU9NFFU3I.svg?originalFilename=eb8e67b0b78401cf18e2e423f61f7a39.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerLs5rLiOI2, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})